.avatar-image{
    width:100%;
  }
.avatar-image:hover{
  filter:drop-shadow(0 -5px 5px red);
}
  .title{
    overflow:show;
    padding-top:15vh;
  }
  .title-text{
    font-size:10vw;
  }