.Certification{
    width:100%;
    margin:auto;
    z-index: 1000;
    padding:0 2% 0;
}

.Certification::after{
    content:"";
    display:block;
    height:2px;
    background-color: var(--overall-back);
    margin:10px auto;
    width:100%;
    filter:drop-shadow(0 0 3px var(--font))
}