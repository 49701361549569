.image-col{
    text-align:center;
}
.cert-image{
    width:50%;
    margin-left:auto;
    margin-right:auto;
}

.score-md{
    text-align:center;
    font-size:22px;
    height:auto;
    vertical-align: middle;
    position:relative;
}

.score-xs{
    font-size:5vw;
}

.score-container{
    position:absolute;
    top:20%;
    width:100%;
}

.first-score::after{
    content:"";
    display:block;
    height:1px;
    background-color: var(--font);
    margin:0;
    width:100%;
}

.score-md .row div{
    width:100%
}

@media (min-width:768px){
    .cert-image{
        width:100%;
        max-width:100px;
    }
} 

.small-text{
    font-variant:small-caps;
}

.score-piece{
    width:100%;
    padding-left:0px;
    padding-right:0px;    
}

.Certification:hover{
    background-color: var(--font-fade);
    transition:0.2s

}

.certification-link{
    color: var(--font);
}
.certification-link:hover{
    color: var(--font);
}