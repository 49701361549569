.container{
  background-color:var(--background);
  max-width:95%;
  width:auto;
  color:var(--font);
  padding-bottom:50px;
  transition:0.2s;
}

h2{
  font-style: italic;
  font-size:1.3rem;
}

.App{
  width:100%;
  background-color:var(--overall-back);
  transition:0.2s;
  
}

/*:root{
  --overall-back: #1A5754;
  --highlight: #37B6AE;
}*/